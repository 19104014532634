body {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

.container {
    flex: 1 0 auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.form-loader-container {
    position: relative;
}

.form-loader{
    width:100%;
    height:100%;
    background:url('/img/loader.gif') no-repeat center #fff;
    position:absolute;
    z-index:10;
    left:0;
}

.form-group .form-control.error {
    width: 100%;
    font-size: initial;
    line-height: initial;
}

.form-group .form-control ~ label.error{
    color: var(--red);
    font-size: 0.7rem;
    text-align: right;
    bottom: 0.4rem;
    float: right;
    right: 1.5rem;
    background: white;
    margin: 0;
    padding: 0 0.4rem;
    width: auto;
}