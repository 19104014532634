.sidebar .sidebar-brand {
    height: 5rem;
    padding: 1rem 2.5rem 1rem 2.5rem;
}
.sidebar.toggled .sidebar-brand {
    padding: 0.5rem;
}

.topbar{
    height: 5rem;
}

@media (max-width: 768px) {
    .sidebar .sidebar-brand {
        padding: 0.5rem;
    }

    .disp-md-none {
        display: none;
    }
}

.form-control[readonly] {
    background-color: white;
}

.calendar-icon {
    padding: 1rem;
    color: var(--primary);
}
.calendar-icon:hover {
    cursor: pointer;
}

.form-loader-container {
    position: relative;
}

.form-loader{
    width:100%;
    height:100%;
    background:url('/img/loader.gif') no-repeat center #fff;
    position:absolute;
    z-index:10;
    left:0;
}

.form-group .form-control.error {
    width: 100%;
    font-size: initial;
    line-height: initial;
}

.form-group .form-control ~ label.error{
    color: var(--red);
    font-size: 0.7rem;
    text-align: right;
    bottom: 0.4rem;
    float: right;
    right: 1.5rem;
    background: white;
    margin: 0;
    padding: 0 0.4rem;
    width: auto;
}